import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { FCM } from '@ionic-native/fcm/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'mail'
    },
    {
      title: 'settings',
      url: '/settings',
      icon: 'paper-plane'
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private fcm: FCM
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
        this.statusBar.styleDefault();
        this.splashScreen.hide();


        //RECEIVED FCM ID
        this.fcm.getToken().then((token) => {
            localStorage.setItem("token", token);
        }, (err) => {
            localStorage.setItem("diag", err);
        })

        //RECEIVED NOTIFICATION
        this.fcm.onNotification().subscribe((data) => {
            if (data.wasTapped) {

            } else {
                localStorage.setItem("diag", data.message);
            }
        })

        //UPDATING TOKEN IF IT EXPIRES
        this.fcm.onTokenRefresh().subscribe((token) => {
            localStorage.setItem("token", token);
        })


    });
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }
}
